<template>
  <div class="content" style="height: 1000px;">
    <!-- <Nav ref="nav"></Nav> -->
    <div class="main-header">
      <div class="logo">湖南博知图文化科技有限公司</div>
      <nav class="main-nav">
        <ul class="nav-ul">
          <li class="nav-li"><a href="./index.html?v=1">主页</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/about">AI博物馆</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/recruit">技术服务支持</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/us">关于我们</a></li>
        </ul>
      </nav>
    </div>
    <div class="main">
      <div class="aside">
        <ul class="aside-ul">
          <li><a href="javascript:void(0);">企业简介</a></li>
          <li><a href="javascript:void(0);">技术支持</a></li>
        </ul>
      </div>
      <div class="main-content">
        <div class="introduction">
          <h6>企业简介</h6>
          <p>湖南博知图文化科技有限公司成立于2018年，注册所在地：湖南省长沙市开福区月湖街道文创路6号马栏山创意中心B栋18层1801房02室</p>
          <p>计算机软件开发、计算机信息技术服务；通信系统自动化软硬件的开发，并提供技术咨询、技术服务；计算机软硬件的销售及维护；软件设计及技术转让，并提供相关技术服务；企业管理咨询；市场调查；企业征信业务；企业信用评估；信用管理咨
          </p>
          <div>询。（依法须经批准的项目，经相关部门批准后方可开展经营活动） 许可项目：第二类增值电信业务；互联网信息服务（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）
            一般项目：互联网数据服务；大数据服</div>
          <div>务；人工智能应用软件开发（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）</div>
          <h6>技术支持</h6>
          <p>电子邮箱：a15873125085@sina.com</p>
        </div>
      </div>
      <div class="main-footer">
        <ul class="footer-ul">
          <li class="footer-li"><a href="#">产品网站</a></li>
          <li class="footer-li"><a href="#">加入我们</a></li>
          <li class="footer-li"><a href="#">服务协议</a></li>
          <li class="footer-li"><a href="#">隐私协议</a></li>
        </ul>
        <p class="footer-a" style="font-size: 14px">
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
            湘ICP备2022012810号-2
          </a>
          版权所有 <a href="#">湖南博知图文化科技有限公司</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件
// import Nav from './../components/Nav.vue'

export default {
  components: {
    // Nav
  },
  data() {
    return {
    }
  },
  methods: {
    // create() {
    //   this.$refs.nav.activeOne = 4
    // }
  },
  computed: {
  },
  mounted() {
    this.create()
  }
}
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.content {
  widows: 100%;
  height: 100%;
}

.main{
  height: 1000px;
}
.main-header {
  height: 105px;
  background-color: #fff;
  box-shadow: 0px 2px 6px 2px rgba(73, 50, 50, 0.2);
}

.logo {
  margin: 0px 40px;
  float: left;
  line-height: 105px;
  font-size: 28px;
  font-weight: 700;
  background-size: 80px 40px;
  padding-left: 85px;
}

.nav-ul {
  float: right;
}

.nav-li {
  float: left;
  padding-right: 40px;
  font-size: 18px;
  line-height: 105px;
}

.nav-li a {
  color: #000;
  text-decoration: none;
}

.nav-li a:visited,
.nav-li a:hover,
.nav-li a:active {
  font-weight: bold;
  text-decoration: underline yellow 6px;
}

a {
  outline: none;
}

dl,
ul,
ol,
menu,
li {
  list-style: none
}

a:active,
a:hover {
  text-decoration: none
}

.aside {
  position: fixed;
  width: 16%;
  float: left;
  top: 125px;
  left: 100px;
}

.aside-ul li {
  font-size: 14px;
  background-color: #fff;
  padding: 10px 0;
  margin: 0 20px 3px 0;
  padding: 15px;
}

.aside-ul li a {
  color: #000000;
  text-decoration: none;
}

.aside-ul li a:visited {
  font-weight: bold;
  /* text-decoration: underline yellow 6px; */
}

.aside-ul li a:hover {
  font-weight: bold;
  text-decoration: underline yellow 6px;
}

.aside-ul li a:active {
  font-weight: bold;
}

.content {
  background: rgb(239, 239, 239);
}

.main-content {
  font-size: 12px;
  background: #ffffff;
  width: 72%;
  margin-top: 20px;
  margin-left: 440px;
}

.introduction {
  padding: 30px 10px;
}

.introduction div {
  margin-bottom: 10px;
}

.introduction p,
.introduction h6 {
  margin-bottom: 10px;
  text-indent: 2rem;
}

.main-footer {
  background-color: #222;
  color: #666;
  text-align: center;
  margin: 0px auto;
}

.main-footer .footer-ul {
  display: inline-block;
}

.footer-li {
  font-size: 14px;
  height: 20px;
  float: left;
  border-right: 1px solid #999;
  padding: 0 20px;
  margin-top: 30px;
}

.footer-li:first-child {
  margin-left: 20px;
}

.footer-li:last-child {
  border: none;
}

.footer-li a,
.footer-a a {
  color: #999;
  text-decoration: none;

}

.footer-a a {
  color: #666;
}

.footer-li a:hover {
  color: #fff;
}
</style>

