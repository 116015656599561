import Vue from 'vue'
import Router from 'vue-router'

import About from '../views/about.vue'
import home from '../views/home'
import Recruit from '../views/recruit.vue'
import Us from '../views/us.vue'
Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: '/',
  name: 'home',
  component: home
},
{
  path: '/about',
  name: 'about',
  component: About
},
{
  path: '/recruit',
  name: 'recruit',
  component: Recruit
},
{
  path: '/us',
  name: 'us',
  component: Us
}
]
const router = new Router({
  routes
})
export default router
