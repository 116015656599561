<template>
  <div class="content">
    <!-- <Nav ref="nav"></Nav> -->
    <div class="main-header">
      <div class="logo">湖南博知图文化科技有限公司</div>
      <nav class="main-nav">
        <ul class="nav-ul">
          <li class="nav-li"><a href="./index.html?v=1">主页</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/about">AI博物馆</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/recruit">技术服务支持</a></li>
          <li class="nav-li"><a href="./index.html?v=1#/us">关于我们</a></li>
        </ul>
      </nav>
    </div>
    <div class="main">
      <div class="aside">
        <ul class="aside-ul">
          <li><a href="#down">产品介绍</a></li>
          <li><a href="javascript:void(0);">用户使用</a></li>
          <li><a href="#support">技术支持</a></li>
        </ul>
      </div>
      <div class="main-article">
      <div id="down">
        <h1 style="padding: 20px 0; text-align: center; font-size: 25px">
          博知图APP - 技术服务支持
        </h1>
        <p>
          <img
            style="width: 90%"
            src="../assets/index_banner.jpg"
            alt="我的信息"
          />
        </p>
        <p></p>
        <p class="title" style="margin-top: 50px; margin-bottom: 20px">登陆系统图示</p>
        <div style="display: flex">
          <p>
            <img
              style="width: 375px"
              src="../assets/view3.png"
              alt="我的信息"
            />
          </p>
          <p>
            <img style="width: 375px" src="../assets/view1.png" alt="登录" />
          </p>
          <p>
            <img style="width: 375px" src="../assets/view2.png" alt="首页" />
          </p>
        </div>
        <p class="title" style="margin-top: 50px; margin-bottom: 12px">系统支持情况</p>
        <p>
          根据联网要求，保证数据通讯的稳定性、可靠性、安全性，带宽应满足视频、数据信息的传输要求。
        </p>

        <p>Android</p>
        <p>支持Android4.0以上任意版本的系统</p>
        <p>IOS8：</p>
        <p>'8.0', '8.1', '8.2', '8.3', '8.4'</p>
        <p>iOS9：</p>
        <p>'9.0', '9.1', '9.2', '9.3'</p>
        <p>iOS10：</p>
        <p>'10.0', '10.1', '10.2', '10.3'</p>
        <p>iOS11：</p>
        <p>'11.0', '11.1', '11.2', '11.3', '11.4'</p>
        <p>iOS12：</p>
        <p>'12.0', '12.1', '12.2', '12.3', '12.4'</p>
        <p>iOS13：</p>
        <p>'13.0', '13.1'</p>
        <p>iOS14：</p>
        <p>官方暂未提供</p>
      </div>
      <div id="support">
        <p class="title">技术支持</p>
        <p style="padding-bottom: 60px;">电子邮箱：a15873125085@sina.com</p>
      </div>
    </div>
    <div class="main-footer">
      <ul class="footer-ul">
        <li class="footer-li"><a href="#">产品网站</a></li>
        <li class="footer-li"><a href="#">加入我们</a></li>
        <li class="footer-li"><a href="#">服务协议</a></li>
        <li class="footer-li"><a href="#">隐私协议</a></li>
      </ul>
      <p class="footer-a" style="font-size: 14px">
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
          湘ICP备2022012810号-2
        </a>
        版权所有 <a href="#">湖南博知图文化科技有限公司</a>
      </p>
    </div>
    </div>
  </div>
</template>

<script>
// import Nav from './../components/Nav.vue'
export default {
  components: {
    // Nav
  },
  data() {
    return {
    }
  },
  methods: {
    // create() {
    //   this.$refs.nav.activeOne = 3
    // },
    // navToPass(num, e) {
    //   this.$router.push({ path: e })
    //   if (this.activeOne === num) return
    //   this.activeOne = num
    // },
    // mounted() {
    //   this.create()
    // }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.content {
  background: rgb(239,239,239);
}
/* 导航栏样式 */
.main-header {
    height: 105px;
    background-color: #fff;
    box-shadow: 0px 2px 6px 2px rgba(73, 50, 50, 0.2);
}

.logo {
    margin: 0px 40px;
    float: left;
    line-height: 105px;
    font-size: 28px;
    font-weight: 700;
    background-size: 80px 40px;
    padding-left: 85px;
}

.nav-ul {
    float: right;
}

.nav-li {
    float: left;
    padding-right: 40px;
    font-size: 18px;
    line-height: 105px;
}

.nav-li a {
    color: #000;
    text-decoration: none;
}

.nav-li a:visited {
    font-weight: bold;
    text-decoration: underline yellow 6px;
}

.nav-li a:hover {
    font-weight: bold;
    text-decoration: underline yellow 6px;
}

.nav-li a:active {
    font-weight: bold;
    text-decoration: underline yellow 6px;
}

a {
    outline: none;
}
dl,
ul,
ol,
menu,
li {
    list-style: none
}
a:active,
a:hover {
    text-decoration: none
}
.aside {
  position: fixed;
  width: 16%;
  float: left;
  top: 125px;
  left: 100px;
}
.aside-ul li{
    font-size: 14px;
    background-color: #fff;
    padding: 10px 0;
    margin: 0 20px 3px 0;
    padding: 15px;
}
.aside-ul li a{
    color: #000000;
    text-decoration: none;
}
.aside-ul li a:visited{
    font-weight:bold;
    /* text-decoration: underline yellow 6px; */
}
.aside-ul li a:hover{
    font-weight:bold;
    text-decoration: underline yellow 6px;
}
.aside-ul li a:active{
    font-weight:bold;
}
.main {
  background: rgb(239,239,239);
}
.main-article{
    font-size: 12px;
    background: #ffffff;
    width: 72%;
    margin-top: 20px;
    margin-left: 440px;
}
.main-article p {
    text-indent: 2em;
    margin-left: 20px;
    margin-top: 12px;

}

.main-article p.title {
    color: #333;
    font-weight: bold;
    margin-left: 20px;
}
.main-footer {
    background-color: #222;
    color: #666;
    text-align: center;
    margin: 0px auto;
}

.main-footer .footer-ul {
    display: inline-block;
}

.footer-li {
  font-size: 14px;
    height: 20px;
    float: left;
    border-right: 1px solid #999;
    padding: 0 20px;
    margin-top: 30px;
}

.footer-li:first-child {
    margin-left: 20px;
}

.footer-li:last-child {
    border: none;
}

.footer-li a,
.footer-a a {
    color: #999;
    text-decoration: none;

}
.footer-a a {
  color: #666;
}
.footer-li a:hover {
    color: #fff;
}
</style>
