import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
import 'swiper/swiper-bundle.min.css'
import {
  swiperAnimateCache,
  swiperAnimate,
  clearSwiperAnimate
} from './js/animate.js'

import Swiper, {
  Pagination,
  Navigation
} from 'swiper'

const animated = require('./css/animate.css')

Swiper.use([Pagination, Navigation])

Vue.use(ElementUI)
Vue.use(Nav)
Vue.use(Footer)
Vue.use(VueFullPage)
Vue.use(animated)

Vue.prototype.$swiperAnimateCache = swiperAnimateCache
Vue.prototype.$swiperAnimate = swiperAnimate
Vue.prototype.$clearSwiperAnimate = clearSwiperAnimate
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
