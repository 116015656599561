<template>
  <div class="recommendPage">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <Nav ref="nav"></Nav>
        <div class="mid-title">
            <div>
              <img src="./../assets/nav-title.png" alt="" />
              <div class="wxsys">微信扫一扫，内容跃然于纸上</div>
              </div>
          </div>
      </swiper-slide>
      <swiper-slide>
         <div class="other-one">
          <div class="photo-pic"></div>
          <div
class="photo ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
            <div
class="photo-tip ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
              <div class="border-l"></div>
              <div class="tip-content">
                <div
class="delayslow ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="0.7s">
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="1s">AR拍照识别</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="1.3s">对着展品轻轻一扫</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="1.6s">文物的前世今生</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="1.9s">立刻呈现在您眼前和耳边</p>
                </div>
              </div>
              <div class="border-r"></div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="other-two">
          <div
class="navlook ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
            <div
class="navlook-tip ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
              <div class="border-l"></div>
              <div class="tip-content">
                <p
class="delay-l ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="0.9s">全新导览</p>
              </div>
              <div class="border-r"></div>
            </div>
          </div>
          <div class="navlook-pic"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="other-thr">
          <div class="zntj-pic"></div>
          <div
class="zntj ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
            <div
class="zntj-tip ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.5s"
          swiper-animate-delay="0.5s">
              <div class="border-l"></div>
              <div class="tip-content">
                <div
class="delay ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.4s"
          swiper-animate-delay="0.7s">
                  <p>智能推荐</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.3s"
          swiper-animate-delay="0.9s">热门、附近博物馆</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.3s"
          swiper-animate-delay="1.1s">展览、每周国宝</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.3s"
          swiper-animate-delay="1.3s">文博、文创</p>
                  <p
class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="0.3s"
          swiper-animate-delay="1.5s">应有尽有</p>
                </div>
              </div>
              <div class="border-r"></div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <Footer
          style="
            height: 60px;
            line-height: 60px;
            background: #999999;
          "
        ></Footer>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'
import Nav from './../components/Nav.vue'
import Footer from './../components/Footer.vue'
import Swiper, { Pagination, Navigation } from 'swiper'

Swiper.use([Pagination, Navigation])

export default {
  components: {
    swiper,
    swiperSlide,
    Nav,
    Footer
  },
  data() {
    const that = this
    return {
      swiperOption: {
        direction: 'vertical',
        speed: 700,
        mousewheel: true,
        mousewheelControl: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        keyboard: {
          enabled: false
        },
        on: {
          init() {
            that.$swiperAnimate(this)
            that.$swiperAnimateCache(this)
          },
          slideChangeTransitionEnd() {
            that.$swiperAnimate(this)
            that.slides.eq(this.activeIndex).find('.ani').removeClass('ani')
          }
        }
      }
    }
  },
  methods: {
    create() {
      this.$refs.nav.activeOne = 2
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted() {
    this.create()
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.allcontent {
  min-width: 1100px;
  width: 100%;
}
.section:nth-child(1) {
  background: url("./../assets/nav-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.mid-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 334px;
  padding: 0 60px;
  font-size: 20px;
  color: #ffffff;
}
.wxsys {
  text-align: center;
  margin-top: 13px;
  font-size: 1.875vw;
  color: #ffffff;
}
.other-one,
.other-two,
.other-thr {
  width: 100%;
  height: 1030px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.other-one {
  background-image: url("./../assets/tu.gif");
}
.other-thr {
  background-image: url("./../assets/tu3.gif");
}
.other-two {
  background: #f9f8f6;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./../assets/tu2.gif");
  background-size: 100%;
  height: 940px;
}
.photo-pic,
.navlook-pic,
.zntj-pic {
  width: 100%;
  height: 100%;
}
.photo,
.navlook,
.zntj {
  position: relative;
  width: 100%;
  height: 50%;
  background: linear-gradient(90deg, #d19e4d 0%, rgba(209, 158, 77, 0) 100%);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.photo,
.zntj {
  background: linear-gradient(90deg, rgba(209, 158, 77, 0) 0%, #d19e4d 100%);
}
.zntj {
  height: 50%;
}
.navlook {
  width: 70%;
  animation-name: InLeft;
  animation-delay: 4.5s;
}
.photo-tip,
.navlook-tip,
.zntj-tip,
.delay,
.delayslow,
.delay p,
.delayslow p {
  position: relative;
}
.navlook-tip .tip-content {
  margin: 48px 40px;
}

.border-l,
.border-r {
  position: absolute;
  width: 40px;
  height: 40px;
}
.border-l {
  border-left: 10px solid #d19e4d;
  border-top: 10px solid #d19e4d;
}
.border-r {
  right: 0;
  bottom: 0;
  border-right: 10px solid #d19e4d;
  border-bottom: 10px solid #d19e4d;
}
.tip-content {
  font-size: 1.57vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6a6a6a;
  margin: 30px 40px;
}
.tip-content p {
  text-align: right;
  height: 49px;
  line-height: 49px;
}
.tip-content p:nth-child(1) {
  display: block;
  font-size: 3.77vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  margin-bottom: 40px;
}
.zntj-tip .tip-content p:nth-child(1) {
  margin-bottom: 65px;
  display: block;
}
#fp-nav.fp-right {
  right: 72px;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: #ffffff;
  opacity: 0.3;
}
.recommendPage .swiper-container{
  position: relative;
  height: 937px;
  width: 100%;
}
.recommendPage >>> .swiper-pagination-bullet-active {
  background: #fff !important;
}
.swiper-slide:nth-child(1) {
  background-image: url('./../assets/nav-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.swiper-container-vertical>.swiper-pagination-bullets {
  right: 100px;
}

.recommendPage >>>.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  opacity: 0.3;
  border-radius: 50%;
}
.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 10px 0;
}
.recommendPage>>>.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet-active {
  height: 22px;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
}
</style>
