<template>
  <div class="container">
    <Nav ref="nav"></Nav>
    <div>
      <div class="iphone">
        <!-- <div class="iphone-mp4"> -->
          <!-- <video
            class="my-video"
            muted
            poster="//ci.xiaohongshu.com/700a0de6-c9ee-4c1c-8e02-94e948d9f611"
            src="//dc.xhscdn.com/a10d0710-80f1-11e9-9036-5948d2131083/new5.27.mp4"
            autoplay
            loop="loop"
          ></video> -->
        <!-- </div> -->
        <div class="iphone-case"></div>
      </div>
      <div class="mid-content">
        <div class="tip1">
          <img src="./../assets/midtitle.png" alt="" />
        </div>
        <div class="tip2">瞰世界，大视界</div>
        <div class="download-ewm">
          <div class="download-item">
            <div
              :class="activeTwo == item.id ? 'cur-dl-item' : 'dl-item'"
              v-for="(item, index) in mid"
              :key="index"
              @click="exchange(item.id)"
            >
              <div class="li-dot"></div>
              <div class="li-title">{{ item.title }}</div>
            </div>
          </div>
          <div class="ewm">
            <img
              v-for="(item, index) in mid"
              :key="index"
              :src="activeTwo == item.id ? item.url : ' '"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <Footer
style="color: #ffffff;
  margin-top: 350px;"
  ></Footer>
  </div>
</template>

<script>
import Nav from './../components/Nav.vue'
import Footer from './../components/Footer.vue'
export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      activeTwo: 1,
      mid: [
        {
          id: 1,
          title: '博知图APP下载',
          url: require('./../assets/app.png')
        },
        {
          id: 2,
          title: '博逛逛小程序',
          url: require('./../assets/guangguang-applets.jpg')
        }
      ]
    }
  },
  methods: {
    exchange(id) {
      if (this.activeTwo === id) return
      this.activeTwo = id
    },
    created() {
      this.$refs.nav.activeOne = 1
    }
  },
  mounted() {
    this.created()
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container {
  padding: 0;
  min-width: 1100px;
  width: 100%;
  height: 1080px;
  background: #ffffff;
  background-image: url("./../assets/bg.png");
  box-sizing: border-box;
}

.mid-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 200px;
  position: relative;
}
.iphone {
  position: absolute;
  width: 315px;
  height: 654px;
  top: 19%;
  left: 24%;
}
.iphone .iphone-case {
  position: absolute;
  width: 340px;
  height: 706px;
  /* background-image: url('https://ci.xiaohongshu.com/3ca6607e-d4a5-4cb9-b455-a746713d8283'); */
  background-image: url('./../assets/midpic.png');
  background-repeat: no-repeat;
  background-size: contain;
}
/* .iphone .iphone-mp4 {
  position: absolute;
  width: 280px;
  height: 590px;
  margin: 18px 20px;
} */
/* .iphone .iphone-mp4 .my-video {
  border-radius: 30px;
  height: 590px;
} */
.iphone .iphone-case {
  -webkit-box-reflect: below 0px
  linear-gradient(transparent 70%, rgba(0, 0, 0, 0.3));
}
/* .iphone .iphone-mp4 .my-video {
  -webkit-box-reflect: below 33px
    linear-gradient(transparent 70%, rgba(0, 0, 0, 0.3));
} */
.tip {
  width: 90px;
  height: 26px;
  margin-left: 10px;
}
.tip1 {
  width: 298px;
  height: 87px;
  margin-top: 139px;
}
.tip2 {
  height: 40px;
  font-size: 44px;
  font-family: SimSun;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  margin-top: 30px;
}
.download-ewm {
  margin-top: 126px;
  white-space: nowrap;
  display: flex;
  margin-left: 60px;
}
.cur-dl-item,
.dl-item {
  width: 360px;
  height: 90px;
  border-radius: 45px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.cur-dl-item {
  background-color: #000000;
  opacity: 0.4;
}
.ewm {
  position: relative;
  height: 170px;
}
.ewm img {
  position: absolute;
  width: 162px;
  height: 170px;
}
.li-dot {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  margin-left: 50px;
  margin-right: 20px;
}
.cur-dl-item .li-dot {
  background-color: #32d5d7;
}
.li-title {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.cur-dl-item .li-title {
  color: #32d5d7;
}
</style>
