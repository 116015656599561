<template>
  <div class="footer">
    <span>Copyright © 2022 湖南博知图文化科技有限公司 </span>
    <font><a href="https://beian.miit.gov.cn/#/Integrated/index" class="gx">湘ICP备2022012810号-2</a></font>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  }
}
</script>

<style>
.footer {
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}

.gx {
  color: #ffffff;
  text-decoration: none;
}

.gx:hover {
  color: #999999;
}
</style>

