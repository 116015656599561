<template>
      <div class="nav-content">
      <div class="nav-content-left">
        <div :data="nav" ref="nav"></div>
        <div class="nav-pic">
          <img src="./../assets/navpic.png" alt="" />
        </div>
      </div>
      <div class="right-content-container">
        <div
          :class="
            activeOne == item.num
              ? 'cur-nav-content-right'
              : 'nav-content-right'
          "
          v-for="(item, index) in nav"
          :key="index"
          @click="navToPass(item.num, item.path)"
        >
          <div class="nav-item">{{ item.name }}</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      activeOne: 0,
      nav: [
        {
          num: 1,
          name: '主页',
          path: '/'
        },
        {
          num: 2,
          name: 'AI博物馆',
          path: '/about'
        },
        {
          num: 3,
          name: '技术服务支持',
          path: '/recruit'
        },
        {
          num: 4,
          name: '关于我们',
          path: '/us'
        }
      ]
    }
  },
  methods: {
    navToPass(num, e) {
      this.$router.push({ path: e })
      if (this.activeOne === num) return
      this.activeOne = num
    },
    getData() {
      return this.$refs.authData.activeOne
    }
  }
}
</script>
